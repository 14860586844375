@import "./bootstrap/our-component-imports.scss";

.dieColumn {
    @extend .px-1;
    @extend .px-lg-2;
}

.dieContainer {
    @extend .border;
    @extend .mx-0;
    @extend .mt-2;
    @extend .p-1;
}

.arrowContainer {
    @extend .ps-0;
    @extend .pe-1;
}

.arrowGroup {
    @extend .w-100;
}

.dieCount {
    @extend .px-0;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @include font-size($h1-font-size);
}

.die {
    @extend .p-1;
    @extend .d-flex;
    @extend .align-items-center;
}

.dieImage {
    width: 3.5em;
    max-height: 100%;
    max-width: 100%;
}

.textDie {
    @extend .die;
}

.textSides {
    @extend .p-0;
    text-align: center;
}

.rollColumn {
    @extend .order-1;
    @extend .order-lg-0;
}

.resetColumn {
    @extend .order-0;
    @extend .order-lg-1;
}

.diceActionContainer {
    @extend .mx-0;
    @extend .mt-3;
    @extend .mb-1;
    @extend .p-1;
    @extend .border;
    @extend .align-items-center;
}

.diceActionContainerElement {
    @extend .px-0;
}

.rollButtonGroup {
    @extend .w-100;
}

.rollButton {
    @extend .border;
}

.pretendFirstRollButton {
    @extend .border;
    @extend .rounded-start;
}

.captionBox {
    @extend .rounded-0;
    @extend .rounded-end;
    @extend .border-start-0;
}

.resetButton {
    @extend .rollButton;
    @extend .w-100;
}

.switchLg {
    @extend .d-flex;
    @extend .align-items-center;

    >input {
        @extend .me-1;
        height: 1.5em !important;
        width: 3em !important;
    }
}