@import "../bootstrap/our-component-imports.scss";
@import "../Modals.module.scss";

.innerColumn {
    @extend .p-0;
}

.diceContainer {
    position: relative;
}

.die {
    height: 4em;
}

.badge {
    height: 50%;
    position: absolute;
    right: -0.5em;
    bottom: -0.2em;
}

.rollGroup {
    @extend .mt-2;
    @extend .w-100;
}

.captionBox {
    @extend .rounded-start-0;
    @extend .rounded-end;
    @extend .border-start-0;
}