@import "./bootstrap/our-component-imports.scss";

.buttonColumn {
    @extend .ps-2;
    @extend .pe-0;
}

.buttonGroup {
    @extend .w-100;
    @extend .my-1;
}

.button {
    @extend .border;
    @extend .w-100;
}

.contentColumn {
    @extend .d-flex;
    @extend .flex-column;
}

.tokenContainer {
    @extend .mt-1;
}

.tokenButton {
    position: absolute;

    @extend .p-0;
}

.tokenImage {
    height: 4em;
    width: auto;
}

.transitionGroupWrapper {
    @extend .d-flex;
    flex-flow: row wrap;
}

.transitionGroup {
    height: 4em;
    width: 4em;

    @extend .me-sm-1;
    @extend .me-lg-2;

    @extend .mb-1;
}

/* starting ENTER animation */
.tokenoutEnter {
    opacity: 1;
    transform: scaleX(0%);
}

/* ending ENTER animation */
.tokenoutEnterActive {
    opacity: 1;
    transform: scaleX(100%);
    transition: all 200ms 200ms ease-out;
}

/* starting EXIT animation */
.tokenoutExit {
    opacity: 1;
    transform: scaleX(100%);
}

/* ending EXIT animation */
.tokenoutExitActive {
    opacity: 0;
    transform: scaleX(0%);
    transition: all 200ms ease-in;
}