@import "./bootstrap/our-component-imports.scss";

.loginBody {
    @extend .pt-3;
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
}

.d1c3 {
    max-height: 30em;
    max-width: 30em;
    @extend .px-5;
}

.loginForm {
    @extend .mt-3;
    @extend .d-grid;
    @extend .gap-1;
}

.footer {
    @extend .mt-3;
}